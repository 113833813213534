.WidgetRunner {
    display: flex;
    flex-direction: column;
    width: 360px;
    margin: auto;
}

.WidgetRunner input {
    margin-bottom: 10px;
}

#root {
    display: flex;
    height: 100vh;
}
